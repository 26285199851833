import React, { Component } from 'react';
import { Icon } from 'react-icons-kit';
import {
  shuffle,
} from 'react-icons-kit/feather';
import { easyPwd, securePwd, reallySecurePwd, wordPwd } from './lib/pwgen';
import Pwd from './components/pwd';
import styles from './App.module.css';

class App extends Component {
  state = {
    easy: '',
    secure: '',
    reallySecure: '',
    word: '',
  }

  randomize = () => {
    this.setState({
      easy: easyPwd(),
      secure: securePwd(),
      reallySecure: reallySecurePwd(),
      word: wordPwd(),
    });
  }

  componentDidMount() {
    this.randomize();
  }

  render() {
    return (
      <div className={ styles.App }>
        <header>
          <h1>Random Password Generator</h1>
        </header>
        <div><button onClick={ this.randomize } className={ styles.Button }><Icon size={ 24} icon={ shuffle } /> Randomize</button></div>
        <Pwd title='Easy' value={ this.state.easy } />
        <Pwd title='Secure' value={ this.state.secure } />
        <Pwd title='Really Secure' value={ this.state.reallySecure } />
        <Pwd title='Words' value={ this.state.word } />
      </div>
    );
  }
}

export default App;
