import React, { Component, Fragment } from 'react';
import { Icon } from 'react-icons-kit';
import {
  copy,
  check,
  xCircle,
} from 'react-icons-kit/feather';
import * as clipboard from 'clipboard-polyfill';

import styles from './styles.module.css';

class CopyButton extends Component {
  state = {
    copySuccess: null,
    copyMessage: '',
  }

  copyPwd = () => {
    clipboard.writeText(this.props.value)
    .then(() => {
      this.setState({
        copySuccess: true,
        copyMessage: 'Copied!',
      });
    })
    .catch(err => {
      this.setState({
        copySuccess: true,
        copyMessage: 'Failed to copy.',
      });
    });
  };

  componentDidUpdate(prevProps) {
    // Clear the status text if the value changes
    if (prevProps.value !== this.props.value) {
      this.setState({
        copySuccess: null,
        copyMessage: '',
      });
    }
  }

  render () {
    const { copySuccess, copyMessage } = this.state;
    return (
      <span>
        <button className={ styles.iconButton } title="Copy to clipboard" onClick={ this.copyPwd }><Icon size={ 16 } icon={ copy } /></button>
        { (copySuccess !== null) &&
        <span className={ styles.copyMessage }><span style={ copySuccess ? {'color': 'green'} : {'color': 'red'} }>
        <Icon size={ 16 } icon={ copySuccess ? check : xCircle } /></span>
        { copyMessage }</span> }
      </span>
    );
  }
}

export default CopyButton;
