import React from 'react';
import CopyButton from '../copyButton';
import styles from './styles.module.css';

export default function Pwd(props) {
  const { title, value } = props;
  return (
    <div>
      <h2>{ title }</h2>
      <p><code className={ styles.Password }>{ value }</code>
      <CopyButton value={ value } />
      </p>
    </div>
  );
}
