import generatePassword from 'password-generator';

import words from './words.json';

const specialChars = [
  '!', '@', '#', '$', '%', '^', '&', '*', '(', ')', '-',
  '=', '+', '~', '{', '}', '[', ']', ':', ';', '?',
]

function randomWord() {
  return words[Math.floor(Math.random() * words.length)];
}

function replaceChar(str, index, char) {
  if (index > str.length-1) return str;
  return str.substr(0, index) + char + str.substr(index + 1);
}

export function easyPwd() {
  const pwLen = 10;
  const minSpecial = 3;
  const maxSpecial = 3;
  let numSpecial = Math.floor(Math.random() * (maxSpecial-minSpecial + 1) + minSpecial);
  const specialIdx = [];
  for (let i = 0; i < numSpecial; i++) {
    var idx;
    do {
      idx = Math.floor(Math.random() * pwLen);
    } while (specialIdx.includes(idx));
    specialIdx.push(idx);
  }
  console.dir(specialIdx);
  let base = generatePassword(10, true);
  console.log(base);
  for (let i = 0; i < numSpecial; i++) {
    if (i % 3 === 0) {
      console.log(`Number ${specialIdx[i]}`);
      base = replaceChar(base, specialIdx[i], Math.floor(Math.random() * 10));
    } else if (i % 3 === 1) {
      console.log(`Special ${specialIdx[i]}`);
      base = replaceChar(base, specialIdx[i], specialChars[Math.floor(Math.random() * specialChars.length)]);
    } else if (i % 3 === 2) {
      console.log(`Upper ${specialIdx[i]}`);
      base = replaceChar(base, specialIdx[i], base.substr(specialIdx[i], 1).toUpperCase());
    }
  }
  return base;
}

export function securePwd() {
  return generatePassword(12, false, /[\w!@#$%^&*()-=+.,?]/);
}

export function reallySecurePwd() {
  return generatePassword(32, false, /[\w!@#$%^&*()-=+.,?]/);
}

export function wordPwd() {
  return `${randomWord()} ${randomWord()} ${randomWord()} ${randomWord()}`
}
